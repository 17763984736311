:root {
    --blue: #027ACB;
    --darkblue: #040BD6;

}

.aboutme--top {
    display: flex;
    justify-content: left;
    margin-top: 1%;
    margin-left: 1%;
}

.aboutme--header {
    font-family: Arial, Helvetica, sans-serif;
    color: var(--blue);
    font-size: 40px;
    font-style: bold;
    display: flex;
    justify-content: center;
    margin-top: 0.2%;
    animation: slideFromRight 0.4s cubic-bezier(0.93,-0.01, 1, 1.07);   

}


.parent--container {
    width: 1200px;
    height: 595px;
    margin: auto;
    margin-top: -1%;
    margin-bottom: 1.5%;
    border-radius: 5%;
    border: 9px solid var(--blue);

}
.peddy--berk {
    float: right;
    width: 335px;
    height: 213px;
    margin-top: 1%;
    margin-right: 1%;
    margin-left: 1%;
    border-radius: 9%;
    border: 4px solid var(--blue);
    animation: slideFromRight 0.6s cubic-bezier(0.93,-0.01, 1, 1.07);
}

.aboutme--text {
    font-size: 21px;
    color: var(--blue);
    margin-top: 1%;
    margin-left: 1%;
    margin-right: 1%;
    margin-bottom: 1%;
}

.footer--aboutme {
    display: flex;
    justify-content: center;
    margin-top: 0.5%;
}

@keyframes slideFromRight {
    0% {
        transform: translatex(800px) translatey(-80px);
    }
    100% {
        transform: translatex(0px) translatey(0px);   
    }
}

